export default class Officer {
	constructor() {
		this.watch = "";
		this.sow = "";
		this.eow = "";
		this.yearsExperience = null;
		this.officer1 = {
			serial: "",
			lastName: "",
			firstName: "",
		};
		this.additionalOfficers = [];
		this.assignmentType = "";
		this.assignment = "";
	}
}
