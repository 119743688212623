export default class Vehicle {
	constructor() {
		this.vehPlate = "";
		this.vehState = "";
		this.vehYear = null;
		this.vehMake = "";
		this.vehModel = "";
		this.vehColor = "";
		this.vehBodyStyle = "";
		this.vehDamage = "";
		this.vehSeatedPosition = null;
	}
}
