<template>
	<FiPrint></FiPrint>
</template>

<script>
import FiPrint from "../modules/fi/print-fi/FiPrint.vue";
export default {
	components: { FiPrint },
};
</script>

<style lang="scss" scoped></style>
