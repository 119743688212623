import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueZxingScanner from "vue-zxing-scanner";
import VueMask from "v-mask";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import db from "./storage.js";
import msal from "vue-msal-fixed";

Vue.prototype.$settings = () => {
	let settings = { ...store.getters["User/settings"] };
	return Vue.observable(settings);
};

Vue.directive("uppercase", {
	inserted: function (el, _, vnode) {
		el.addEventListener("input", async function (e) {
			e.target.value = e.target.value.toUpperCase();
			vnode.componentInstance.$emit("input", e.target.value.toUpperCase());
		});
	},
});

Vue.config.productionTip = false;

Vue.use(VueZxingScanner);
Vue.use(VueMask);
Vue.use(db);
Vue.use(msal, {
	auth: {
		tenantId: "642fd61c-34dd-4fd0-af8d-443576485883",
		clientId: "314c8cad-c1be-47d7-a62c-ca0f6a0bedc4",
		// redirectUri: "https://localhost:8080", //dev
		redirectUri: "https://causeway.lapd.tech", //live
		requireAuthOnInitialize: true,
		navigateToLoginRequestUrl: true,
	},
	request: {
		scopes: ["user.read"],
	},
	framework: {
		globalMixin: true,
	},
	graph: {
		callAfterInit: true,
		endpoints: {
			profile: "/me",
		},
	},
	cache: {
		cacheLocation: "localStorage",
	},
});
Vue.use(VuetifyGoogleAutocomplete, {
	apiKey: "AIzaSyBjLCbmZzo_A5J12qxN_-PZezC2xNWTPRQ",
});

import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

// this part resolves an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
	iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
	iconUrl: require("leaflet/dist/images/marker-icon.png"),
	shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
